<template>
  <div class="page-container stock-kit-page">
    <portal to="page-name">Kit de produtos</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">Kit de produtos</div>
      </div>
      <div class="card-body">
        <div v-if="!openKit">
          <small class="text-info" v-if="form.status === 'finished'">
            * Este kit já foi finalizado
          </small>
          <small class="text-info" v-if="form.status === 'available'">
            * Este kit está disponível
          </small>
          <small class="text-info" v-if="form.status === 'canceled'">
            * Este kit foi cancelado
          </small>
          <div class="card-detail">
            <div class="card-detail-title">Dados do kit</div>
            <div class="card-detail-data">
              <div class="columns">
                <div class="column col-6 col-sm-12 form-group">
                  <label class="form-label text-bold">Nome</label>
                  {{ form.name }}
                </div>
                <div class="column col-2 col-md-6 col-sm-12 form-group">
                  <label class="form-label text-bold">Código</label>
                  {{ form.code }}
                </div>
                <div class="column col-2 col-md-6 col-sm-12 form-group">
                  <label class="form-label text-bold">Validade</label>
                  {{ form.validity | date }}
                </div>
                <div class="column col-2 col-md-6 col-sm-12 form-group">
                  <label class="form-label text-bold">Quantidade</label>
                  {{ form.quantity.current | number }} de {{ form.quantity.total | number }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-detail">
            <div class="card-detail-title">Produtos</div>
            <div class="card-detail-data">
              <table class="table">
                <thead>
                <tr>
                  <th class="hide-sm" width="40px">#</th>
                  <th>Produto</th>
                  <th class="text-center">Unidade</th>
                  <th>Lote</th>
                  <th>Validade</th>
                  <th class="text-center">Quantidade</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in form.products" :key="i">
                  <td class="hide-sm">{{ i + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ measure.getName(item.outputUnit) }}</td>
                  <td>{{ item.lot ? item.lot.name : '' }}</td>
                  <td>{{ item.lot ? item.lot.validity : '' | date }}</td>
                  <td class="text-center" width="100px">
                    {{ item.quantity | number(3) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="columns form-group">
            <div class="column col-4 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.name.$error}">
              <label for="name" class="form-label">Nome</label>
              <input id="name" class="form-input"
                     v-model="form.name" @blur="$v.form.name.$touch()"
                     placeholder="Nome para identificação do kit"
              />
              <template v-if="$v.form.name.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.name.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-4 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.placeId.$error}">
              <label class="form-label">Local</label>
              <select id="origin-place" class="form-select"
                      v-model="form.placeId"
                      :disabled="form.products.length > 0"
                      @blur="$v.form.placeId.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="item in places"
                        :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
              <template v-if="$v.form.placeId.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.placeId.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-2 col-md-12 col-sm-12 form-group">
              <label class="form-label">Validade</label>
              <dx-input-date
                id="date" class="form-input text-center"
                disabled
                v-model="form.validity"
              />
            </div>
            <div class="column col-2 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.quantity.total.$error}">
              <label class="form-label">Quantidade</label>
              <dx-input-number
                id="date" class="form-input text-center"
                v-model="form.quantity.total"
                @blur="$v.form.quantity.total.$touch()"
              />
              <template v-if="$v.form.quantity.total.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.quantity.total.required">Campo obrigatório</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.quantity.total.minValue">Campo obrigatório</div>
              </template>
            </div>
          </div>
          <div class="divider" />
          <div class="form-group">
            <label class="form-label">Produto</label>
            <st-autocomplete
              id="product"
              ref="productAutocomplete"
              v-model="product.item"
              :items="product.items"
              :disabled="!form.placeId"
              :auto-select-one-item="false"
              :get-label="getProductLabel"
              :loading="product.loading"
              placeholder="Informe o nome, código ou lote"
              @item-selected="selectProductItem"
              @focus="product.items = []"
              @update-items="updateProductItems">
              <a slot-scope="scope" class="menu-item-tile">
                <figure class="avatar">
                  <fa-icon :icon="['fal', 'box']"></fa-icon>
                </figure>
                <div class="name"
                     v-html="scope.highlight(scope.item.name)"></div>
              </a>
              <button tabindex="-1" slot="action"
                      @mouseup="openProductList"
                      :disabled="!form.placeId"
                      class="btn btn-icon btn-action input-group-btn btn-neutral">
                <fa-icon :icon="['fal', 'search']"></fa-icon>
              </button>
            </st-autocomplete>
          </div>
          <div class="empty" v-if="form.products.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Produtos</p>
            <p class="empty-subtitle">
              Nenhum produto adicionado. Utilize o campo acima para realizar a busca.
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" width="40px">#</th>
              <th>Produto</th>
              <th class="text-center">Unidade</th>
              <th>Lote</th>
              <th>Validade</th>
              <th class="text-center">Quantidade</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in form.products" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td class="text-center">{{ measure.getName(item.outputUnit) }}</td>
              <td>{{ item.lot ? item.lot.name : 'Sem lote' }}</td>
              <td>
                <span v-if="item.lot">{{ item.lot.validity | date }}</span>
                <span v-else>-</span>
              </td>
              <td class="text-center" width="100px">
                <div :class="{'has-error': item.quantity > item.limit}">
                  <dx-input-number v-model="item.quantity"
                                   class="form-input text-center"
                                   @change="updateTotal(item)"
                                   :precision="3" maxlength="11"
                  />
                </div>
              </td>
              <td class="text-right" style="width:50px">
                <button class="btn btn-sm btn-action btn-icon btn-error"
                        @click="removeItem(item.id, i)">
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                v-if="openKit"
                @click="save(false)"
                :disabled="providing || saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn btn-warning mr-1"
                v-if="openKit"
                @click="save(true)"
                :disabled="providing || saving"
                :class="{loading: providing}">
          Disponibilizar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
<!--    <modals-product-list-->
<!--      v-if="modalProductList.show"-->
<!--      :show="modalProductList.show"-->
<!--      :placeId="form.originPlaceId"-->
<!--      :has-balance="true"-->
<!--      :show-kits="false"-->
<!--      @productData="selectProduct"-->
<!--      @close="modalProductList.show = false"-->
<!--    />-->
    <dx-modal title="Lotes do produto" :value="modalProductLot.show"
              @input="modalProductLot.show = false" id="modal-product-lots" size="sm">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th>Lote</th>
          <th class="text-center">Validade</th>
          <th class="text-center">Quantidade</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in modalProductLot.items" :key="i"
            class="c-hand"
            @click="selectProductLot(item)">
          <td>{{ item.lot ? item.lot.name : 'Sem lote' }}</td>
          <td class="text-center" width="120px">
            <span v-if="item.lot">{{ item.lot.validity | date }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center text-bold" width="120px"
              :class="item.quantity <= 0 ? 'text-error' : 'text-primary'">
            {{ item.quantity }}
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="modalProductLot.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/src/validators';
import moment from 'moment';
import * as measure from 'src/data/measure-units';
import { FINISH_OUTPUT } from '@/data/actions/modules/stock';
import formMixin from '../../../mixins/form';
// import ModalProductList from '../../service/components/List.vue';

export default {
  components: {
    // ModalProductList,
  },
  mixins: [formMixin],
  data() {
    return {
      measure,
      isNew: false,
      loading: false,
      saving: false,
      providing: false,
      places: [],
      product: {
        item: null,
        loading: false,
        items: [],
      },
      modalProductLot: {
        show: false,
        items: [],
      },
      modalProductList: {
        show: false,
      },
      form: this.blankForm(),
    };
  },
  computed: {
    openKit() {
      return this.form.status === 'open';
    },
    canAccessFinishOutput() {
      if (FINISH_OUTPUT) {
        return this.$can(FINISH_OUTPUT);
      }
      return true;
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        quantity: {
          total: { required, minValue: minValue(1) },
        },
        placeId: { required },
      },
    };
  },
  async mounted() {
    this.loadPlaces();
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`/stock-kits/${this.form.id}`)
        .then(({ data }) => {
          this.form = {
            id: data.id,
            code: data.code,
            name: data.name,
            validity: data.validity,
            quantity: data.quantity,
            date: data.date,
            status: data.status,
            placeId: data.place.id,
            notes: data.notes,
            products: data.products,
          };
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadPlaces() {
      this.places = [];

      const params = {
        active: true,
        limit: 0,
      };

      return this.$http
        .get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(() => {});
    },
    async save(available = false) {
      if (available && !this.canAccessFinishOutput) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$v.form.$touch();
      if (this.$v.form.$error || this.saving || this.providing) {
        return;
      }

      if (available && this.form.products.length === 0) {
        this.$toast.show('Adicione um ou mais produtos', { type: 'error' });
        return;
      }

      if (available) {
        this.providing = true;
      } else {
        this.saving = true;
      }

      let notBalance = false;
      this.form.products.forEach(({ quantity, limit }) => {
        if (quantity > limit) {
          notBalance = true;
        }
      });

      if (notBalance) {
        this.saving = false;
        this.providing = false;
        this.$toast.show('Existe(m) produto(s) com saldo insuficiente!', { type: 'error' });
        return;
      }

      const output = this.clone(this.form);
      output.status = available ? 'available' : 'open';
      output.quantity.current = output.quantity.total;

      const request = this.isNew
        ? this.$http.post('/stock-kits', output)
        : this.$http.put(`/stock-kits/${this.form.id}`, output);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`/stock/kits/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
          }
          this.form.status = available ? 'available' : 'open';
          this.$toast.show('Registro salvo');
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error' });
          }
        })
        .then(() => {
          this.saving = false;
          this.providing = false;
        });
    },
    getProductLabel(item) {
      return item ? item.name : '';
    },
    updateProductItems(text) {
      const value = text.trim();

      if (value && value.length === 8) {
        // kits
      }

      if (value.length) {
        this.product.loading = true;
        const params = {
          search: value,
          limit: 10,
        };
        this.$http
          .get('/products', { params })
          .then(({ data }) => {
            if (data.items && data.items[0].selected) {
              this.selectProductItem(data.items[0]);
            } else {
              this.product.items = data.items;
            }
          })
          .catch(() => {})
          .finally(() => {
            this.product.loading = false;
          });
      } else {
        this.product.items = [];
      }
    },
    async selectProductItem(item) {
      const params = {
        placeId: this.form.placeId,
        productId: item.id,
        hasBalance: true,
      };
      await this.findProductBalance(params)
        .then(({ items }) => {
          if (items.length === 1) {
            this.setProduct(items[0]);
          } else if (items.length > 1) {
            this.modalProductLot.items = items;
            this.modalProductLot.show = true;
          }
        });

      this.$refs.productAutocomplete.searchText = null;
    },
    removeItem(id, i) {
      this.form.products.splice(i, 1);
      this.updateValidity();
    },
    openProductList() {
      this.modalProductList.show = true;
    },
    findProductBalance(params) {
      return this.$http.get('/stock-balances', { params })
        .then(({ data }) => data)
        .catch(() => {});
    },
    selectProduct(data) {
      if (data.quantity <= 0) {
        this.$toast.show('Este produto está sem saldo em estoque!', { type: 'error' });
        return;
      }
      this.setProduct(data);
      this.modalProductList.show = false;
    },
    setProduct(data) {
      const found = this.form.products
        .find((item) => {
          if (data.lot) {
            return item.id === data.id
              && item.lot
              && item.lot.id === data.lot.id;
          }
          return item.id === data.id && !item.lot;
        });

      if (found) {
        found.quantity += 1;
        found.total = found.cost * found.quantity;
      } else {
        this.form.products.push({
          id: data.product.id,
          name: data.product.name,
          outputUnit: data.product.outputUnit,
          lot: data.lot,
          quantity: 1,
          cost: data.cost,
          total: data.cost,
          limit: data.quantity,
        });
        this.updateValidity();
      }
    },
    selectProductLot(data) {
      if (data.quantity <= 0) {
        this.$toast.show('Este lote está sem saldo em estoque!', { type: 'error' });
        return;
      }
      this.setProduct(data);
      this.modalProductLot.show = false;
    },
    updateTotal(item) {
      if (!item.quantity || item.quantity <= 0) {
        item.quantity = 1;
      }
      item.total = item.cost * item.quantity;
    },
    updateValidity() {
      this.form.validity = '';
      this.form.products
        .filter(({ lot }) => lot)
        .forEach(({ lot }) => {
          if (!this.form.validity) {
            this.form.validity = lot.validity;
          } else if (moment(lot.validity).diff(this.form.validity, 'days') > 0) {
            this.form.validity = lot.validity;
          }
        });
    },
    blankForm() {
      return {
        id: '',
        placeId: '',
        code: '',
        name: '',
        date: moment().format('YYYY-MM-DD[T]HH:mm'),
        validity: '',
        quantity: {
          total: 1,
          current: 1,
        },
        status: 'open',
        notes: '',
        products: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.stock-kit-page {
  .card-detail {
    background-color: lighten($gray-color-light, 5%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    .card-detail-title {
      font-weight: bold;
      margin-bottom: $layout-spacing;
    }
  }
  .form-checkbox {
    line-height: 1rem;
    margin: 0;
    min-height: 0;
    i {
      top: 0.15rem;
    }
  }
}
</style>
