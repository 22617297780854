export const statuses = {
  open: 'Aberto',
  available: 'Disponível',
  canceled: 'Cancelado',
  finished: 'Finalizado',
};

export function getName(value) {
  return statuses[value];
}
